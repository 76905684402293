@media (max-width: 768px) {
  .w-50-80 {
    width: 90%;
  }
}
.w-50-80 {
  width: 50%;
}

.bg-img-about {
  position: relative;
  vertical-align: middle;
  top: 0;
  width: 100%;
  height: 90vh;
  object-fit: cover;
  background: url(http://goo.gl/vyAs27) no-repeat scroll 0 0;
}

.about-top-cover {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  background: rgba(0, 0, 0, 0.6);
}

.service-cover {
  content: "\A";
  position: absolute;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  background: url(http://goo.gl/vyAs27) no-repeat scroll 0 0;

  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  -webkit-transition: all 0.5s;
}
.service-cover:hover {
  opacity: 0;
}

.message-title {
  position: relative;
  top: 0;
  font-size: 32pt;
  margin-bottom: 10%;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.transition-row {
  width: 80%;
}

.arrow-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

@media (max-width: 768px) {
  .message-title {
    margin-bottom: 10px;
  }
  .vision-title {
    margin-bottom: 50px;
  }

  .bg-img-about {
    height: 50vh;
    min-height: 300px;
  }

  .about-top-cover {
    height: 50vh;
    min-height: 300px;
  }

  .transition-row {
    width: 100%;
  }
}

#transition-text {
  font-size: 24pt;
}

.message-text {
  font-size: 16pt;
  height: 50%;
  padding-bottom: calc(10% - 1rem);
  padding-left: 10%;
  padding-right: 10%;
  font-family: Arial, Helvetica, sans-serif;
}

.bg-card-service {
  position: relative;
  vertical-align: middle;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.divider {
  height: 4px;
  width: 20%;
  left: 0;
  background-color: #ffffff;
}


.subscribe_card {
  max-width: 80%;
  vertical-align: middle;
  top: 0;
  object-fit: cover;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.desc_photo {
  max-width: 5%;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  clear: both;
  display: flex;
}

.clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.con {
  width: 300px;
  height: 200px;
  background: #996600;
}

.box {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
}

.plop {
  width:30px;
  height:30px;
  vertical-align:middle;
}