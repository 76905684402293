.banner-title {
  background: linear-gradient(148deg, #126f95, #07435b);
  color: white;
  padding: 0.2em;
  margin-right: 30%;
}

.cover-image {
  width: 100%;
}
@media (min-width: 576px) {
  .cover-image {
    width: 30%;
  }
}
