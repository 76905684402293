/* make the customizations */
$theme-colors: (
  "primary": #7f152b,
  "info": #0096aa,
  "warning": #ceaf00,
  "success": #65a30e,
  "danger": #c93c0e,
  "secondary": #3c0425,
  "light": #fafbfc,
  "dark": #343a40,
);

.rmsc {
  --rmsc-gray: #6c757d !important;
  --rmsc-main: #7f152b !important;
}

.rmsc .select-item {
  margin: 0;
}

.white-50 {
  background-color: #ffffff88;
  width: 100%;
}

.white-75 {
  background-color: #ffffffcc;
  width: 100%;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
