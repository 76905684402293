.close-button:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%), 0 0 10px 0 rgb(0 0 0 / 10%);
}

.cart-adaptative-width {
  width: 90vw;
}

.font-cart {
  font-size: 20px;
}
@media (min-width: 768px) {
  .cart-adaptative-width {
    width: 60vw;
  }

  .font-cart {
    font-size: 32px;
  }
}
