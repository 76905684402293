.study-card:hover {
  cursor: pointer;

  transform: scale(1.02);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%), 0 0 20px 0 rgb(0 0 0 / 10%);
}

.study-card {
  transition: transform 0.2s, -webkit-transform 0.2s;
}

#scrollable-studies {
  z-index: 1;
}

.buy-btn {
  height: calc(100% + 1rem);
  transform: translate(0, -0.5rem);
}
