.sidebar {
  position: fixed;
  top: 3em;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
}

.menu-option:hover {
  background-color: #e3e3e3 !important;
  cursor: pointer;
}

.bg-chosen {
  background-color: #eeeeee !important;
}

.side-menu {
  transition: flex 0.5s, max-width 0.5s, padding 0.5s, border 0s;
  height: 100vh;
}

.results {
  bottom: 0;
  transition: flex-basis 0.5s, max-width 0.5s, padding 0.5s, border 0s, left 0.5s;
}

#collapse-btn {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 70px;
  border-radius: 0;
  transition: left 0.5s, right 0.5s;
}

#collapse-arrow {
  transition: transform 0.5s;
}
