.highlightable:hover {
  cursor: pointer;

  transform: scale(1.02);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%), 0 0 20px 0 rgb(0 0 0 / 10%);
}

.highlightable {
  transition: transform 0.2s, -webkit-transform 0.2s;
  height: 100%;
}

.hidden {
  filter: blur(0.6rem);
}

.shader {
  position: absolute;
  top: 0;
  opacity: 0.2;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
}

.text-subscribe {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }