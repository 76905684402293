.bottom-bar-inline {
  max-height: 40px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  align-self: flex-end;
}
.bottom-bar-fixed {
  max-height: 40px;
  /* position: absolute;
  bottom: 0; */
  z-index: 100;
  width: 100vw;
  position: fixed;
  bottom: 0;
}
