.bg-label {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-size: larger;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.bg-img {
  position: relative;
  vertical-align: middle;
  top: 0;
  width: 100%;
  height: 85vh;
  max-height: calc(100vh - 220px);
  object-fit: cover;
}

@media (max-width: 768px) {
  .bg-img {
    height: 35vh;
  }
}

.cont {
  overflow-x: hidden;
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cover {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.cover:hover {
  opacity: 0;
}

.title {
  font-size: 32pt;
}

.comment {
  font-size: 16pt;
}

@media (max-width: 768px) {
  .title {
    font-size: 24pt;
  }

  .comment {
    font-size: 12pt;
  }
}
