.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: white;
  pointer-events: none;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
