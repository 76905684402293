html,
body,
#root,
#root > div {
  height: 100%;
}

h1,
h2 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

body {
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
