.scrollable-companies {
  height: 100%;
}
@media (max-width: 768px) {
  .scrollable-companies {
    height: auto;
  }
}

:root {
  --background: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
}
[data-theme='dark'] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}