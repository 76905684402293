.leaflet-container {
    width: 100%;
    height: 94vh;
}

.company_logo {
    max-width: 80%;
    vertical-align: middle;
    top: 0;
    object-fit: cover;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.centered_text {
    max-width: 80%;
    vertical-align: middle;
    top: 0;
    object-fit: cover;

    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}